import React, { useEffect } from 'react';

export const useDetectTextOverflow = (selector: string) => {
  const textRef = React.useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = React.useState(false);

  useEffect(() => {
    if (textRef.current && textRef.current.textContent) {
      const span = textRef.current.querySelector(selector) as HTMLSpanElement;

      if (span) {
        setIsOverflow(span.offsetWidth < span.scrollWidth);
      }
    }
  }, [textRef.current?.textContent]);

  return {
    isOverflow,
    textRef,
  };
};
