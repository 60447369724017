import React from 'react';

import { SelectorType } from 'application/components/SearchItems/ChooseItemList/ChooseItemListItem';
import { PaginationInfoType } from 'domain/api/graphql/generated';
import SearchItems from './SearchItems';

import styles from './index.module.scss';
import { TEXTS } from '../../texts';
import { PointsListCheckboxGroupPointType, PointsListDataItem } from '../../types';

export type VideoStreamPointsListProps = {
  className?: string;
  selectedPoints: PointsListDataItem[];
  points: PointsListCheckboxGroupPointType[];
  isLoading: boolean;
  onChange: (points: PointsListDataItem[]) => void;
  paginationSettings?: PaginationInfoType;
  loadMore?: () => Promise<void>;
  hintText?: string;
};

const VideoStreamPointsList = ({
  className,
  selectedPoints,
  isLoading,
  points,
  onChange,
  loadMore,
  paginationSettings,
  hintText,
}: VideoStreamPointsListProps) => {
  const onChangeWrapper = (value: boolean, point: PointsListCheckboxGroupPointType) => {
    if (value) {
      onChange([...selectedPoints, point]);
    } else {
      onChange(selectedPoints.filter((current) => current.id !== point.id));
    }
  };

  const onChangeHandler = (changedPoints: PointsListCheckboxGroupPointType[]) => {
    onChange(changedPoints);
  };

  return (
    <>
      {hintText && <div className={styles.VideoStreamPointsListHint}>{hintText}</div>}
      <SearchItems
        items={points}
        isLoading={isLoading}
        isReloading={isLoading}
        selectedItems={selectedPoints}
        selectorType={SelectorType.switch}
        onChange={onChangeHandler}
        loadMore={loadMore}
        paginationSettings={paginationSettings}
        hasMultiselect
        hasSearch
        className={className}
        onChangeWrapper={onChangeWrapper}
        emptySearchText={TEXTS.POINTS_LIST_EMPTY}
        inputPlaceholder={TEXTS.SEARCH}
      />
    </>
  );
};

export default VideoStreamPointsList;
