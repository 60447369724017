export const SEARCH_DELAY = 500;
export const FN_OPTIONS = { wait: SEARCH_DELAY };

export const SILENT_MEDIAFILE_ID = 64096;

export const IS_OFERTA_CONFIRMED = 'IS_OFERTA_CONFIRMED';
export const TOP_BANNER_TYPE_CLOSED = 'TOP_BANNER_TYPE_CLOSED';

export const PAGE_SIZE_XXS = 10;
export const PAGE_SIZE_XS = 25;
export const PAGE_SIZE_S = 50;
export const PAGE_SIZE_M = 75;
export const PAGE_SIZE_L = 100;
export const PAGE_SIZE_XXL = 1000;

/* Common Vite Variables */
export const BASE_URL = import.meta.env.BASE_URL;
export const MODE = import.meta.env.MODE;
export const DEV = import.meta.env.DEV;
export const PROD = import.meta.env.PROD;

/* Sensitive Data, access only throw Vite */
export const ZVUK_API_GRAPHQL_URL = import.meta.env.ZVUK_API_GRAPHQL_URL;
export const ZVUK_AMP_API_KEY = import.meta.env.ZVUK_AMP_API_KEY;
export const ZVUK_CAPCHA_API_KEY = import.meta.env.ZVUK_CAPCHA_API_KEY;
export const ZVUK_DEV_PROXY_TARGET = import.meta.env.ZVUK_DEV_PROXY_TARGET;
export const ZVUK_CDN_URL = import.meta.env.ZVUK_CDN_URL;
export const ZVUK_GTM_KEY = import.meta.env.ZVUK_GTM_KEY;
export const ZVUK_ALICE_AUTH_SERVER_URI = import.meta.env.ZVUK_ALICE_AUTH_SERVER_URI;

const process = window.process;

/* Non sensitive Common Data */
export const ZVUK_ENV = process && process.env ? process.env.ENV : import.meta.env.ZVUK_ENV;
export const ZVUK_SENTRY_ENV = process && process.env ? process.env.SENTRY_ENV : import.meta.env.ZVUK_SENTRY_ENV;
export const ZVUK_ANALYTIC_ENV = process && process.env ? process.env.ANALYTIC_ENV : import.meta.env.ZVUK_ANALYTIC_ENV;
export const ZVUK_DEV_ALLOWED_HOST =
  process && process.env ? process.env.DEV_ALLOWED_HOST : import.meta.env.ZVUK_DEV_ALLOWED_HOST;

/* Non sensitive Feature Flags */
export const ZVUK_FEATURE_PHONE_AUTH_AVAILABLE =
  process && process.env ? process.env.FEATURE_PHONE_AUTH_AVAILABLE : import.meta.env.ZVUK_FEATURE_PHONE_AUTH_AVAILABLE;

export const ZVUK_WITH_BROADCAST_SCHEDULE =
  process && process.env ? process.env.WITH_BROADCAST_SCHEDULE : import.meta.env.ZVUK_WITH_BROADCAST_SCHEDULE;

export const ZVUK_FEATURE_SHELF_PAGE =
  process && process.env ? process.env.FEATURE_SHELF_PAGE : import.meta.env.ZVUK_FEATURE_SHELF_PAGE;

export const ZVUK_FEATURE_SUB_COLLECTIONS =
  process && process.env ? process.env.FEATURE_SUB_COLLECTIONS : import.meta.env.ZVUK_FEATURE_SUB_COLLECTIONS;

export const ZVUK_SHOW_CAPTCHA = process && process.env ? process.env.SHOW_CAPTCHA : import.meta.env.ZVUK_SHOW_CAPTCHA;

export const ZVUK_SHOW_CONTACT_WITH_MANAGER_BUTTON =
  process && process.env
    ? process.env.SHOW_CONTACT_WITH_MANAGER_BUTTON
    : import.meta.env.ZVUK_SHOW_CONTACT_WITH_MANAGER_BUTTON;

export const ZVUK_NEW_POINT_AD_LIST =
  process && process.env ? process.env.NEW_POINT_AD_LIST : import.meta.env.ZVUK_NEW_POINT_AD_LIST;

/* Application Pages Routing Paths */
export const ACTIVATION_PAGE_PATH = 'activation';
export const ACTIVATION_SUCCESS_PAGE_PATH = 'activation/success';
export const ACTIVATION_TRIAL_WARNING_PAGE_PATH = 'activation/trial';

export const ADS_PAGE_PATH = 'promo/:id?';
export const ADS_PAGE_PATH_ALIAS = '/promo';

export const BILLING_PAGE_PATH = 'billing/:state/:param?';
export const BILLING_PAGE_PATH_ALIAS = 'billing/info';

export const BROADCAST_SCHEDULE_PAGE_PATH = 'broadcast-schedule/:id/:adCampaignId?';

export const BUSINESS_PROFILE_PAGE_PATH = 'business-profile/:id';

export const COMPANY_PAGE_PATH = 'company';
export const SETTINGS_PAGE_PATH = 'settings';

export const CONTRACT_MIGRATION_PAGE_PATH = 'accept-oferta';

export const DEBUG_PAGE_PATH = 'debug';

export const DOWNLOAD_APP_PAGE_PATH = 'download-app';

export const FORGOT_PAGE_PATH = 'forgot';

export const INVITE_PAGE_PATH = 'invite';

export const FORGOT_COMPLETE_PAGE_PATH = 'forgot-complete';

export const LOCK_PAGE_PATH = 'lock';

export const LOGIN_PAGE_PATH = 'login';
export const LOGIN_PAGE_PATH_ALIAS = '/login';

export const LOGOUT_PAGE_PATH = '/logout';

export const NOT_FOUND_PAGE_PATH = '*';

export const PASSWORD_RESET_PAGE_PATH = 'password-reset';
export const PASSWORD_RESET_PAGE_PATH_ALIAS = 'restore-password/set-new-password';

export const POINTS_PAGE_PATH = 'points/:id';
export const POINTS_MENU_PATH = '/points/all';

export const PRICE_PLAN_PAGE_PATH = 'price-plan';

export const REDIRECT_PAGE_PATH = '/redirect/:from';

export const REGISTER_PAGE_PATH = 'register';
export const REGISTER_PAGE_PATH_ALIAS = 'error-callback';

export const SPECIAL_REGISTER_PAGE_PATH = 'register/:source';

export const REPORTS_PAGE_PATH = 'reports';

export const REVIEW_STREAM_PAGE_PATH = 'review/:streamId';

export const SHELF_PAGE_PATH = 'shelf';

export const STREAMS_PAGE_PATH = 'streams';
export const STREAMS_STREAM_CATEGORY_BY_ID_PATH = `${STREAMS_PAGE_PATH}/:category/:id?`;
export const STREAM_SEARCH_PAGE_PATH = `${STREAMS_PAGE_PATH}/search/:category?/:id?`;
export const STREAM_SUB_COLLECTIONS_PAGE_PATH = `${STREAMS_PAGE_PATH}/subcollection/:subcollectionId/:id?`;
export const STREAM_GROUPS_GROUP_PAGE_PATH = `${STREAMS_PAGE_PATH}/group/:id?`;
export const STREAM_GROUPS_COLLECTION_PAGE_PATH = `${STREAMS_PAGE_PATH}/collection/:collectionId/:id?`;

export const VIDEO_STREAMS_PAGE_PATH = 'video-streams/:id';

export const WEB_PLAYER_PAGE_PATH = 'web-player/:bindingCode';

export const WELCOME_PAGE_PATH = 'create-company';
