import React from 'react';
import { PaginationInfoType } from 'domain/api/graphql/generated';
import InView from 'react-intersection-observer';
import { PointsListCheckboxGroupPointType } from 'application/pages/VideoStreamsPage/renderer/types';
import ChooseItemListItem, { SelectorType } from './ChooseItemListItem';
import PointItemContent from '../../PointItemContent';

export type ChooseItemListType = {
  onChange: (value: any, currentItem: PointsListCheckboxGroupPointType) => void;
  itemList: Array<PointsListCheckboxGroupPointType>;
  selectedItems: Array<PointsListCheckboxGroupPointType>;
  selectorType: SelectorType;
  isLoading: boolean;
  isReloading?: boolean;
  paginationSettings?: PaginationInfoType;
  loadMore?: () => void;
};

const ChooseItemList = ({
  itemList,
  onChange,
  selectorType,
  isLoading,
  selectedItems,
  loadMore,
  paginationSettings,
  isReloading,
}: ChooseItemListType) => {
  const showSkeleton = isLoading || (isReloading && !itemList.length);
  const skeletonItems = showSkeleton ? new Array(20).fill(null).map((_, id) => ({ id: String(id) })) : undefined;

  return (
    <>
      {itemList.map((currentItem: PointsListCheckboxGroupPointType) => (
        <ChooseItemListItem
          isChecked={selectedItems.map((selectedItem) => selectedItem.id).includes(currentItem.id)}
          currentItem={currentItem}
          onChange={(value: boolean) => onChange(value, currentItem)}
          selectorType={selectorType}
          isLoading={isLoading || isReloading}
          isDisabled={isReloading || isLoading}>
          <PointItemContent
            isLoading={isLoading}
            currentItem={currentItem}
          />
        </ChooseItemListItem>
      ))}

      {paginationSettings?.hasNextPage && (
        <InView
          onChange={(inView) => inView && loadMore?.()}
          key="infinite_medialist_hidden_loading_zone"
        />
      )}

      {showSkeleton &&
        skeletonItems?.map((currentItem: PointsListCheckboxGroupPointType) => (
          <ChooseItemListItem
            isChecked={selectedItems.map((selectedItem) => selectedItem.id).includes(currentItem.id)}
            currentItem={currentItem}
            onChange={(value: boolean) => onChange(value, currentItem)}
            selectorType={selectorType}
            isLoading
            isDisabled>
            <PointItemContent
              isLoading
              currentItem={currentItem}
            />
          </ChooseItemListItem>
        ))}
    </>
  );
};

export default ChooseItemList;
