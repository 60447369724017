import React, { useState } from 'react';
import classNames from 'classnames';

import CompanyTagChip, {
  VISIBLE_TITLE_LENGTH,
  VISIBLE_TITLE_LENGTH_MOBILE,
} from 'application/components/CompanyTagChip';

import { CompanyTagPureType } from 'domain/api/graphql/generated';

import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';

import Text from '@zvuk-b2b/react-uikit/ui/Text';
import { SelectorContentType } from './types';
import { TEXTS } from './texts';

import styles from './index.module.scss';

const MAX_VISIBLE_TAGS = 2;

const getVisibleTags = (tags: CompanyTagPureType[], isExpanded: boolean) => {
  if (isExpanded) {
    return tags;
  }

  return tags.slice(0, MAX_VISIBLE_TAGS);
};

const PointItemContent = ({ currentItem, isLoading, className }: SelectorContentType) => {
  const { isDesktop } = useMedia();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (!currentItem?.address && !isLoading) {
    return <></>;
  }

  const switchExpandedState = () => {
    setIsExpanded(!isExpanded);
  };

  const isMoreMaxVisible = currentItem && currentItem.tags && currentItem.tags.length > MAX_VISIBLE_TAGS;

  return (
    <div className={classNames(styles.pointListItemContent, className)}>
      <Text
        elementId="drawer_video_stream_address"
        isLoading={isLoading}
        data-loading={isLoading}>
        {!isLoading && currentItem?.address}
      </Text>

      <div
        data-loading={isLoading}
        className={styles.pointListTags}>
        {!isLoading &&
          currentItem &&
          currentItem.tags &&
          getVisibleTags(currentItem.tags, isExpanded).map((tag) => (
            <CompanyTagChip
              key={tag.id}
              id={tag.id}
              title={tag.title || ''}
              visibleTitleLength={isDesktop ? VISIBLE_TITLE_LENGTH : VISIBLE_TITLE_LENGTH_MOBILE}
            />
          ))}

        {!isExpanded && isMoreMaxVisible && !isLoading && (
          <Button
            type={ButtonType.PLAIN}
            elementId="drawer_company_tags_expand_button"
            className={styles.pointListTagsButtonMore}
            onClick={switchExpandedState}>
            {TEXTS.MORE_BUTTON_TEXT(currentItem!.tags!.length - MAX_VISIBLE_TAGS)}
          </Button>
        )}

        {isExpanded && isMoreMaxVisible && !isLoading && (
          <Button
            type={ButtonType.PLAIN}
            elementId="drawer_company_tags_hide_button"
            className={styles.pointListTagsButtonHide}
            onClick={switchExpandedState}>
            {TEXTS.HIDE_BUTTON_TEXT}
          </Button>
        )}
      </div>
    </div>
  );
};

export default React.memo(PointItemContent);
