import { TEXTS } from '../texts';

interface Message {
  message: string;
}

export const replaceTextByErrorMessage = ({ message }: Message): string => {
  if (/duplicate key value/gi.test(message)) {
    return TEXTS.DUPLICATE_MEDIAFILE_ERROR;
  }

  return message;
};
