export const TEXTS = {
  MENU_TITLE_POINTS: 'Адреса',
  MENU_TITLE_STREAMS: 'Волны',
  MENU_TITLE_VIDEO_STREAMS: 'Видео-волны',
  MENU_TITLE_AD: 'Реклама',
  MENU_TITLE_BILLING: 'Оплата',
  MENU_TITLE_PRICE_PLAN: 'Оплата',
  MENU_TITLE_SETTINGS: 'Настройки',
  MENU_TITLE_SHOW_CERTIFICATE: 'Сертификат',
  MENU_TITLE_EXIT: 'Выход',
  MENU_TITLE_REPORTS: 'Отчёты',
  MENU_TITLE_BUSINESS_PROFILE: 'Бизнес-профили',
  MENU_TITLE_SHELF: 'Интеграции',
};
