export const TEXTS = {
  PAGE_TITLE: 'Видео-волны',
  CREATE_VIDEO_STREAM: 'Создать видео-волну',
  MB: 'Мб',
  FILE_FORMS: ['файл', 'файла', 'файлов'],
  SETTINGS: 'Настройки',
  POINTS: 'Адреса',
  POINTS_LIST_EMPTY: 'У вас нет адресов',
  VIDEO_STREAM_TURNED_OFF: 'Видео-волна пока нигде не включена',
  SAVE: 'Сохранить',
  START_STREAM: 'Запустить видео-волну',
  VIDEO_STREAM_POINTS_SAVE_SUCCESS_TEXT: 'Адреса видео-волны успешно сохранены',
  MODAL_BIND_SUCCESS_BUTTON_CANCEL_TEXT: 'Вернуться к видео-волнам',
  MODAL_BIND_SUCCESS_TO_POINTS_TEXT: 'Перейти к адресам',
  MODAL_BIND_SUCCESS_TITLE: 'Видео-волна запущена!',
  SEARCH: 'Поиск по адресам',
  VIDEO_STREAM_INFO_TITLE: 'Видео-волна',
  STREAM_INFO_CHANGE: 'Изменить',
  CREATE_VIDEO_STREAM_MODAL_TITLE: 'Создание видео-волны',
  CREATE_VIDEO_STREAM_MODAL_SELECT_BUTTON: 'Выбрать',
  CREATE_VIDEO_STREAM_MODAL_BUTTON_UPLOAD: 'Загрузить файл до 500Мб',
  CREATE_VIDEO_STREAM_INPUT_PLACEHOLDER: 'Поиск',
  CREATE_VIDEO_STREAM_FORMATS: '1920х1080px, .mp4',
  CREATE_VIDEO_STREAM_SELECT_FILES: 'Или выберете из загруженных файлов:',
  CREATE_VIDEO_STREAM_EMPTY_FILES: 'Нет загруженных файлов',
  UPLOADING: 'Загружается...',

  CREATE_VIDEO_STREAM_TITLE: 'Создание видео-волны',
  EDIT_VIDEO_STREAM_TITLE: 'Изменение видео-волны',

  EDIT_VIDEO_STREAM_TITLE_LABEL: 'Название видео-волны',

  EDIT_VIDEO_STREAM_DESCRIPTION_PLACEHOLDER: 'Краткая информация о вашей компании или описание бизнес-профиля',
  EDIT_VIDEO_STREAM_DESCRIPTION_LABEL: 'Описание',

  ADD_FILE: 'Добавить файл',

  REMOVE_FROM_STREAM: 'Удалить из видео-волны',
  NEXT: 'Далее',
  MODAL_BIND_TITLE: 'Запуск видео-волны',
  MODAL_BIND_BUTTON_SUBMIT_TEXT: 'Запустить видео-волну',
  MODAL_BIND_ADD_NEW_POINT_TEXT: 'Добавить новый адрес',
  DUPLICATE_MEDIAFILE_ERROR: 'Вы уже загружали этот медиафайл ранее. Вы можете найти его в медиахранилище',
};
