import React, { useEffect } from 'react';
import { useUploadMediaFilesMutation } from 'domain/api/graphql/generated';
import { useFilesUpload } from 'application/providers/FilesUploadProvider';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';
import { IVideoMediafile } from '../types';

import { TEXTS } from '../texts';
import { replaceTextByErrorMessage } from './utils';

interface Props {
  companyId: string;
  refetch: () => void;
  videoMediafiles?: IVideoMediafile[];
  loading: boolean;
  uploadMediaFilesMutation: ReturnType<typeof useUploadMediaFilesMutation>[0];
  hasPrevMediafiles?: boolean;
}

const UPLOAD_QUEUE_NAME = 'video_mediafies';
const acceptFiles = { 'video/mpeg': ['.mp4'] };

export const useSelectVideoMediafilesController = ({
  companyId,
  refetch,
  videoMediafiles,
  loading,
  uploadMediaFilesMutation,
  hasPrevMediafiles,
}: Props) => {
  const [selectedItems, setSelectedItems] = React.useState<IVideoMediafile[]>([]);
  const notification = useNotificationContext();

  const nextButtonTitle = hasPrevMediafiles ? TEXTS.NEXT : TEXTS.CREATE_VIDEO_STREAM_MODAL_SELECT_BUTTON;

  const onSelectedChange = (value: boolean, item: IVideoMediafile) => {
    if (value) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(selectedItems.filter((current) => current.id !== item.id));
    }
  };

  const onUpload = async (file: File) => {
    try {
      const data = await uploadMediaFilesMutation({
        variables: {
          input: {
            file,
            companyId,
          },
        },
      });

      if (data?.errors?.length) {
        notification.showNotification({
          type: NotificationColors.ERROR,
          children: replaceTextByErrorMessage(data.errors[0]),
          delay: NOTIFICATION_DEFAULT_DELAY,
        });
      }
    } catch (err) {
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: (err as Error).message,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }
  };

  const { onClickUpload, setDropProps, setQuId, initQue, loading: uploadLoading, uploadingFiles } = useFilesUpload();

  useEffect(() => {
    initQue(UPLOAD_QUEUE_NAME, onUpload);
  }, []);

  useEffect(() => {
    setQuId(UPLOAD_QUEUE_NAME);
    return () => {
      setQuId('');
    };
  }, []);

  useEffect(() => {
    setDropProps?.({
      multiple: true,
      accept: acceptFiles,
    });
  }, []);

  useEffect(() => {
    if (!loading && !uploadingFiles.length) {
      refetch();
    }
  }, [uploadingFiles.length]);

  const items = React.useMemo(() => {
    const uploadingVideoMediafiles = uploadingFiles.map((file) => ({
      title: file.name,
      uploading: true,
    }));

    return [...uploadingVideoMediafiles, ...(videoMediafiles || [])];
  }, [uploadingFiles, videoMediafiles]);

  return {
    onSelectedChange,
    selectedItems,
    onClickUpload,
    uploadLoading,
    items,
    nextButtonTitle,
  };
};
