fragment MediafileInfoType on MediafilePureType {
  id
  rightholderName
  title
  performerTitle
  filename
  uri
  duration
  genres
  values {
    id
    title
    color
  }
  valueIds
  type
  explicit
  sourcefiles {
    ...SourcefileTypeFragment
  }
}

fragment MediaFilesRandomType on MediafilePureType {
  id
  title
  performerTitle
  filename
  uri
  duration
  type
  explicit
  sourcefiles {
    ...SourcefileTypeFragment
  }
}

fragment StreamShortType on StreamPureType {
  id
  title
  description
  isTemplate
  templateType
  cover {
    miniatureUri
  }
  genres {
    tag {
      id
      title
    }
  }
  demoMediafile {
    id
    filename
    sourcefiles {
      ...SourcefileTypeFragment
    }
  }
}

fragment StreamInfoType on StreamPureType {
  ...StreamShortType
  points {
    id
    address
  }
  contentBlocks {
    id
    copyFromId
    title
    startDayTime
    endDayTime
    startDate
    endDate
  }
  demoMediafile {
    id
    filename
    sourcefiles {
      ...SourcefileTypeFragment
    }
  }
  mediafilesCount
  duration
  genres {
    tag {
      id
      title
    }
  }
  hasThematicBlock
  mediafilesLiked
}

fragment PlayerDeviceInfoType on PlayerDevicePureType {
  id
  requiredVersionUrl
  supportedFeatures
  bindingCode
  type
  bindedAt
  supportedFeatures
}

fragment PointInfoType on PointPureType {
  id
  comment
  timezone
  fileFormat
  address
  license {
    ...LicenseShortType
  }
  isEnabled
  sleepOptions {
    enabled
    settings {
      day
      start
      end
    }
  }
  device {
    id
    requiredVersionUrl
    bindingCode
    type
    volume

    ...PlayerDeviceInfoType
  }
  stream {
    ...StreamShortType
    hasThematicBlock
  }
  playingStatus {
    code
    color
    title
    progress
  }
  amplifyOptions {
    amplifyMediafile
    amplifyPromo
    amplifyInstant
  }
  tags {
    id
    companyId
    title
  }
  adcampaigns {
    id
    type
  }
  videoStream {
    id
    title
  }
  withMusicCut
  cutDuration
}

fragment PointShortType on PointPureType {
  id
  address
  comment
  isEnabled
  device {
    ...PlayerDeviceInfoType
  }
  stream {
    ...StreamShortType
  }
  playingStatus {
    code
    color
    title
    progress
  }
  tags {
    id
    companyId
    title
  }
  certificateIsAvailable
  cutDuration
  withMusicCut
  fileFormat
}

fragment PointSpecialType on PointPureType {
  id
  address
  isEnabled
  device {
    ...PlayerDeviceInfoType
  }
  stream {
    id
    title
  }
  playingStatus {
    code
  }
  tags {
    id
    companyId
    title
  }
}

fragment PointMetadataType on PointPureType {
  id
  address
  isEnabled
  adcampaigns {
    id
    type
  }
  stream {
    id
    title
  }
  license {
    id
  }
}

fragment LicenseShortType on LicensePureType {
  id
  autoRenewal
  paymentState
  expiresAt
  modified
}

fragment LicenseInfoType on LicensePureType {
  id
  point {
    id
    address
    isEnabled
    playingStatus {
      code
      color
      title
      progress
    }
  }
  autoRenewal
  paymentState
  expiresAt
  modified
}

fragment CompanyContractPaymentTermsTypeInfoType on CompanyContractPaymentTermsType {
  amount
  payFrequencyMonth
}

fragment CompanyContractInfoType on CompanyContractPureType {
  id
  name
  number
  date
  typeContract
  isConfirmed
  billingEnabled
  paymentTerms {
    ...CompanyContractPaymentTermsTypeInfoType
  }
  status
}

fragment InvoiceInfoType on InvoicePureType {
  id
  status
  amount
  created
  type
  isActual
}

fragment CompanyDeviceRequestInfoType on CompanyDeviceRequestPureType {
  companyId
  deviceType
  status
  pointId
  lastRequestDate
}

fragment UserCompanyShortType on UserCompanyPureType {
  id
  requisite {
    ... on RequisiteRUPureType {
      emailForDocuments
    }
    ... on RequisiteKZPureType {
      emailForDocuments
    }
    ... on RequisiteBYPureType {
      emailForDocuments
    }
  }
}

fragment UserCompanyInfoType on UserCompanyPureType {
  id
  hasMinimumData
  country
  created
  actualName
  validated
  businessSphereId
  isBillingEnabled
  streamLibrariesIds
  hasDesktopPlayers
  hasInstantAdCampaigns
  zvukProfileCount
  deviceRequests {
    ...CompanyDeviceRequestInfoType
  }
  users {
    id
    email
    role
  }
  invoices {
    ...InvoiceInfoType
  }
  contractMigrations {
    id
    contractType
    status
  }
  contracts {
    ...CompanyContractInfoType
  }
  referral {
    uuid
    registeredUsers {
      id
      email
      phone
      registrationSource
    }
  }
  requisite {
    ... on RequisiteRUPureType {
      inn
      kpp
      ogrn
      organizationShortname
      organizationFullname
      organizationAddress
      organizationType
      managementPost
      managementName
      emailForDocuments
    }
    ... on RequisiteKZPureType {
      iin
      bin
      organizationShortname
      organizationFullname
      organizationAddress
      organizationType
      managementPost
      managementName
      emailForDocuments
    }
    ... on RequisiteBYPureType {
      unp
      organizationShortname
      organizationFullname
      organizationAddress
      organizationType
      managementPost
      managementName
      emailForDocuments
    }
  }
  trialPeriod {
    startedAt
    finishedAt
    lengthDays
    lengthMinutes
    isFinished
    isExpired
  }
  uiFeatures {
    isBillingEnabled
    isShowReports
    isChangePricePlanAvailable
    isOffertaChanged
    isCertLetterAvailable
    isInstantAdcampaignAvailable
    isBusinessProfileAvailable
    isVideoAvailable
  }
  pricePlan {
    alias
  }
}

fragment UserInfoType on UserExtendPureType {
  id
  email
  phone
  isTest
  registrationSource
  smartLkAccess
  personalAccountAccess
  mainCompany {
    ...UserCompanyInfoType
  }
  companies {
    ...UserCompanyInfoType
  }
}

fragment UserInvitationInfoType on UserInvitationType {
  id
  email
  isSent
  isAccepted
  isActive
}

fragment AdCampaignTimingSettingsInfoType on AdCampaignTimingSettingsType {
  dayEndTime
  dayStartTime
  endDate
  startDate
  weekdays {
    monday
    friday
    saturday
    sunday
    thursday
    tuesday
    wednesday
  }
}

fragment AdCampaignShortType on AdCampaignPureType {
  id
  title
  enable
  type
  promos {
    id
  }
  adPromos {
    id
    promoId
    title
    filename
    duration
    promo {
      sourcefiles {
        ...SourcefileTypeFragment
      }
    }
  }
  points {
    id
  }
  timingSettings {
    ...AdCampaignTimingSettingsInfoType
  }
  tags {
    id
    companyId
    title
  }
  durationPromos
}

fragment AdCampaignMetadataType on AdCampaignPureType {
  id
  title
  enable
  type

  timingSettings {
    ...AdCampaignTimingSettingsInfoType
  }
  tags {
    id
    companyId
    title
  }
}

fragment AdCampaignInfoType on AdCampaignPureType {
  id
  title
  enable
  period
  interval
  type
  prevMediafileRequired
  timingSettings {
    ...AdCampaignTimingSettingsInfoType
  }
  points {
    id
    deviceType
    address
  }
  adPromos {
    id
    promoId
    title
    filename
    duration
    time
    performerTitle
    promo {
      sourcefiles {
        ...SourcefileTypeFragment
      }
    }
  }
  tags {
    id
    companyId
    title
  }
}

fragment ThematicContentBlockInfoType on ThematicContentBlockPureType {
  id
  title
  eventTitle
  isEnabled
  startDate
  endDate
}

fragment CompanyThematicContentBlockInfoType on CompanyThematicContentBlockPureType {
  id
  playFromDate
  playToDate
  isActive
  isEnabled
  title
  eventTitle
}

fragment CollectionShortType on LibraryCollectionPureType {
  id
  title
  poster {
    id
    uri
  }
}

fragment CollectionType on LibraryCollectionPureType {
  ...CollectionShortType
  streams {
    id
    stream {
      ...StreamShortType
    }
  }
}

fragment GroupType on LibraryGroupPureType {
  id
  title
  collections {
    ...CollectionShortType
  }
}

fragment StreamLibraryType on LibraryPureType {
  id
  title
  groups {
    ...GroupType
  }
}

fragment CompanyPlaybackReportSettingType on CompanyPlaybackReportSettingsType {
  rightholderId
  isGenerateReport
  title
  reportType
  emptyReportTitle
  helpLink
}

fragment CompanyReportType on CompanyPlaybackReportType {
  title
  rightholderId
  dateFrom
  dateTo
  status
  reportFileUri
  id
  reportType
}

fragment PaymentMethodInfoType on PaymentMethodObjectPureType {
  last4
  cardType
  expiryYear
  expiryMonth
}

fragment PricePlanInfoType on PricePlanPureType {
  id
  title
  titleForManager
  alias
  currency
  useByDefault
  titleForManager
  periods {
    id
    title
    payFrequencyMonth
    rows {
      ...PricePlanRowInfoType
    }
  }
  rows {
    ...PricePlanRowInfoType
  }
  offerLink
}

fragment PricePlanRowInfoType on PricePlanRowPureType {
  id
  amount
  stepRangeLower
  stepRangeUpper
}

fragment CompanyDiscountInfoType on OrganizationDiscountPureType {
  id
  title
  startDate
  endDate
  invoiceWithDiscountLimit
  payFrequencyMonth
  value
  valueType
  organizationId
  status
  slug
}

fragment UserFeatureOnboarding on UserFeaturesOnboardingType {
  feature
  isOnboarded
}

fragment NotificationFragment on NotificationType {
  modelId
  notificationType
  message
  title
  confirm
  isBlocked
}

fragment MediafileApprovalFragment on MediafileApprovalType {
  mediafile {
    id
    rightholderName
    title
    performerTitle
    filename
    uri
    explicit
    type
    sourcefiles {
      ...SourcefileTypeFragment
    }
  }
  timestamp
  eventType
}

fragment BusinessPlaylistType on ZvukPlaylistType {
  id
  companyId
  profileId
  streamId
  isPublished
  description
  modified
  tracksCount
  remotePlaylistLink
  poster
  title
  source
  syncStatus
  isAutoCreated
}

fragment BusinessProfileType on ZvukProfileType {
  id
  company {
    id
  }
  isPublished
  description
  companyName
  site
  avatar
  poster
  bannerLink
  bannerWeb
  bannerMobile
  remoteProfileLink
  syncStatus
  created
  playlists {
    ...BusinessPlaylistType
  }
}

fragment SourcefileTypeFragment on SourcefileType {
  url
  mainFormat
}
