import { useEffect, useLayoutEffect, useMemo } from 'react';

import { MenuItemDataType, MenuItemTarget } from 'application/providers/MenuItemsProvider/types';
import { RouterUtils } from 'application/providers/RouterProvider';
import PointsPage, { POINTS_PAGE_PATH, IdParamValues } from 'application/pages/PointsPage';
import VideoStreamsPage, {
  VIDEO_STREAMS_PAGE_PATH,
  VideoStreamsPageParamsValues,
} from 'application/pages/VideoStreamsPage';
import PricePlanPage, { PRICE_PLAN_PAGE_PATH } from 'application/pages/PricePlanPage';
import StreamGroupsPage, { StreamsCategoryParamValues, STREAMS_CATEGORY_PATH } from 'application/pages/StreamPage';
import { useInjection, UsePointRepositoryType, KEYS } from 'application/providers/DIContainerProvider';
import CompanyPage, { COMPANY_PAGE_PATH } from 'application/pages/CompanyPage';
import BillingPage, { BILLING_PAGE_PATH, StateParamValues } from 'application/pages/BillingPage';
import { useMenuItemsContext } from 'application/providers/MenuItemsProvider';
import { useAccountContext } from 'application/providers/AccountProvider';
import { useProfileContext } from 'application/providers/ProfileProvider';
import AdsPage, { ADS_PAGE_PATH_ALIAS } from 'application/pages/AdsPage';
import { useFeatureFlagsContext } from 'application/providers/FeatureFlagsProvider';
import ReportsPage, { REPORTS_PAGE_PATH } from 'application/pages/ReportsPage';
import BusinessProfilePage, {
  BUSINESS_PROFILE_PAGE_PATH,
  BusinessProfilePageIdParamValues,
} from 'application/pages/BusinessProfilePage';
import ShelfPage, { SHELF_PAGE_PATH } from 'application/pages/ShelfPage';
import CompanyUtils from 'application/utils/CompanyUtils';

import IconMapPin from '@zvuk-b2b/react-uikit/ui/icons/MapPin';
import IconMusic from '@zvuk-b2b/react-uikit/ui/icons/Music';
import IconCreditCard from '@zvuk-b2b/react-uikit/ui/icons/CreditCard';
import IconUser from '@zvuk-b2b/react-uikit/ui/icons/User';
import IconReports from '@zvuk-b2b/react-uikit/ui/icons/Reports';
import FileText from '@zvuk-b2b/react-uikit/ui/icons/FileText';
import IconVoicemail from '@zvuk-b2b/react-uikit/ui/icons/Voicemail';
import IconSettings from '@zvuk-b2b/react-uikit/ui/icons/Settings';
import IconBriesCase from '@zvuk-b2b/react-uikit/ui/icons/Briefcase';
import IconGrid from '@zvuk-b2b/react-uikit/ui/icons/Grid';

import useCurrentPricePlan from 'domain/billing/useCurrentPricePlan';
import { CompanyContractKind } from 'domain/api/graphql/generated';

import IconPlayCircle from '@zvuk-b2b/react-uikit/ui/icons/PlayCircle';
import { TEXTS } from './texts';

const useMainMenuItems = () => {
  const { isFeatureShelfPage, isShowReports, isCertLetterAvailable, isVideoAvailable } = useFeatureFlagsContext();

  const { currentCompany, currentUser, actualContract } = useAccountContext();
  const { data: currentPricePlan, request } = useCurrentPricePlan();
  const { showProfile } = useProfileContext();

  const { showCertModal } = useMenuItemsContext();

  const usePointRepository = useInjection<UsePointRepositoryType>(KEYS.POINT_REPOSITORY);
  const { clearCurrentPoint } = usePointRepository();

  const offerLink = currentPricePlan.pricePlan?.pricePlan?.offerLink;

  useEffect(() => {
    if (currentCompany && currentCompany.pricePlan?.alias !== currentPricePlan.pricePlan?.pricePlan?.alias) {
      request(currentCompany.id);
    }
  }, [currentCompany?.pricePlan?.alias, currentPricePlan.pricePlan?.pricePlan?.alias]);

  return useMemo<MenuItemDataType[]>(() => {
    const items = [
      {
        pathname: RouterUtils.generatePath(STREAMS_CATEGORY_PATH, {
          category: StreamsCategoryParamValues.RECOMMENDED,
        }),
        state: { search: 'reset' },
        title: TEXTS.MENU_TITLE_STREAMS,
        icon: IconMusic,
        target: MenuItemTarget.DEFAULT,
        config: StreamGroupsPage.config,
        onClick: clearCurrentPoint,
        isVisible: true,
      },
      {
        pathname: RouterUtils.generatePath(VIDEO_STREAMS_PAGE_PATH, {
          id: VideoStreamsPageParamsValues.ALL,
        }),
        state: { search: 'reset' },
        title: TEXTS.MENU_TITLE_VIDEO_STREAMS,
        icon: IconPlayCircle,
        target: MenuItemTarget.DEFAULT,
        config: VideoStreamsPage.config,
        onClick: clearCurrentPoint,
        isVisible: isVideoAvailable,
      },
      {
        pathname: RouterUtils.generatePath(POINTS_PAGE_PATH, { id: IdParamValues.ALL }),
        title: TEXTS.MENU_TITLE_POINTS,
        icon: IconMapPin,
        target: MenuItemTarget.DEFAULT,
        config: PointsPage.config,
        onClick: clearCurrentPoint,
        isVisible: true,
      },
      {
        pathname: ADS_PAGE_PATH_ALIAS,
        title: TEXTS.MENU_TITLE_AD,
        icon: IconVoicemail,
        target: MenuItemTarget.DEFAULT,
        config: AdsPage.config,
        isVisible: true,
      },
      {
        pathname: REPORTS_PAGE_PATH,
        title: TEXTS.MENU_TITLE_REPORTS,
        icon: IconReports,
        target: MenuItemTarget.DEFAULT,
        config: ReportsPage.config,
        isVisible: isShowReports,
      },
      {
        pathname: RouterUtils.generatePath(BUSINESS_PROFILE_PAGE_PATH, { id: BusinessProfilePageIdParamValues.ALL }),
        title: TEXTS.MENU_TITLE_BUSINESS_PROFILE,
        icon: IconBriesCase,
        target: MenuItemTarget.DEFAULT,
        config: BusinessProfilePage.config,
        isVisible: true,
      },
      {
        pathname: SHELF_PAGE_PATH,
        title: TEXTS.MENU_TITLE_SHELF,
        icon: IconGrid,
        target: MenuItemTarget.DEFAULT,
        config: ShelfPage.config,
        isVisible: isFeatureShelfPage,
      },
      {
        pathname: RouterUtils.generatePath(BILLING_PAGE_PATH, { state: StateParamValues.INFO }),
        title: TEXTS.MENU_TITLE_BILLING,
        icon: IconCreditCard,
        target: MenuItemTarget.BOTTOM,
        config: BillingPage.config,
        onClick: clearCurrentPoint,
        isVisible:
          currentCompany?.isBillingEnabled === true && actualContract?.typeContract === CompanyContractKind.Offerta,
      },
      {
        pathname: RouterUtils.generatePath(PRICE_PLAN_PAGE_PATH),
        title: TEXTS.MENU_TITLE_PRICE_PLAN,
        icon: IconCreditCard,
        target: MenuItemTarget.BOTTOM,
        config: PricePlanPage.config,
        isVisible: currentCompany?.isBillingEnabled === false,
      },
      {
        title: TEXTS.MENU_TITLE_SETTINGS,
        icon: IconSettings,
        target: MenuItemTarget.BOTTOM,
        pathname: COMPANY_PAGE_PATH,
        config: CompanyPage.config,
        isVisible: true,
      },
      {
        title: TEXTS.MENU_TITLE_SHOW_CERTIFICATE,
        icon: FileText,
        target: MenuItemTarget.BOTTOM,
        onClick: () => {
          showCertModal();
        },
        isVisible: isCertLetterAvailable && !!CompanyUtils.getCompanyINNByCountry(currentCompany),
      },
      {
        title: currentUser?.email || currentUser?.phone || currentUser?.firstName,
        icon: IconUser,
        target: MenuItemTarget.PROFILE,
        onClick: () => showProfile(),
        isVisible: true,
      },
    ];

    return items;
  }, [
    currentCompany,
    isShowReports,
    actualContract,
    offerLink,
    isFeatureShelfPage,
    isCertLetterAvailable,
    isVideoAvailable,
  ]);
};

const useMenusPathnames = () => {
  const { mainMenu } = useMenuItemsContext();

  const mainMenuItems = useMainMenuItems();

  useLayoutEffect(() => mainMenu.setItems(mainMenuItems), [mainMenuItems]);
};

export default useMenusPathnames;
