import React, { FC } from 'react';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import InputSearch from '@zvuk-b2b/react-uikit/ui/InputSearch';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import Text from '@zvuk-b2b/react-uikit/ui/Text';
import SelectVideoMediafilesList from '../SelectVideoMediafilesList';
import { useVideoMediafiesPresenter } from '../presenters/useVideoMediafiesPresenter';
import { TEXTS } from '../texts';

import styles from './index.module.scss';
import { useSelectVideoMediafilesController } from './useSelectVideoMediafilesController';

const SelectVideoMediafilesModal: FC<ModalProps[ModalTypes.SELECT_VIDEO_MEDIAFILES]> = ({
  close,
  openModal,
  prevVideoMediafiles = [],
  flow,
  title,
  description,
  videoStreamId,
  refetchSteam,
}) => {
  const [search, setSearch] = React.useState<string>('');
  const {
    videoMediafiles,
    loading,
    companyId,
    refetch,
    uploadMediaFilesMutation,
    paginationInfo: paginationSettings,
    loadMore,
  } = useVideoMediafiesPresenter(search);

  const { onSelectedChange, selectedItems, onClickUpload, uploadLoading, items, nextButtonTitle } =
    useSelectVideoMediafilesController({
      companyId,
      refetch,
      videoMediafiles,
      loading,
      uploadMediaFilesMutation,
      hasPrevMediafiles: Boolean(prevVideoMediafiles?.length),
    });

  const onClickNext = async () => {
    await close();
    openModal(ModalTypes.CREATE_VIDEO_STREAM, {
      selectedFiles: [...selectedItems, ...prevVideoMediafiles],
      openModal,
      flow,
      title,
      description,
      videoStreamId,
      refetch: refetchSteam,
    });
  };

  return (
    <ModalScreen
      title={TEXTS.CREATE_VIDEO_STREAM_MODAL_TITLE}
      contentClassName={styles.modal}
      titlePlacement="left"
      onClose={close}
      actionButtons={[
        <Button
          key="button_video_mediafies_next"
          elementId="button_video_mediafies_next"
          type={ButtonType.PRIMARY}
          isDisabled={!selectedItems.length && !prevVideoMediafiles.length}
          isLoading={loading}
          onClick={onClickNext}>
          {nextButtonTitle}
        </Button>,
      ]}>
      <InputSearch
        containerClassName={styles.SearchInput}
        value={search}
        elementId="search_input_items_list"
        onChange={setSearch}
        placeholder={TEXTS.CREATE_VIDEO_STREAM_INPUT_PLACEHOLDER}
      />

      <Button
        key="button_video_mediafies_upload"
        elementId="button_video_mediafies_upload"
        type={ButtonType.SECONDARY}
        isLoading={loading || uploadLoading}
        onClick={onClickUpload}>
        {TEXTS.CREATE_VIDEO_STREAM_MODAL_BUTTON_UPLOAD}
      </Button>
      <div className={styles.FormatsText}>
        <Text elementId="formats_text">{TEXTS.CREATE_VIDEO_STREAM_FORMATS}</Text>
      </div>
      {Boolean(videoMediafiles?.length) && (
        <div className={styles.SelectFilesText}>
          <Text elementId="select_files_text">{TEXTS.CREATE_VIDEO_STREAM_SELECT_FILES}</Text>
        </div>
      )}

      <SelectVideoMediafilesList
        itemList={items}
        selectedItems={selectedItems}
        isLoading={loading}
        onChange={onSelectedChange}
        emptySearchText={TEXTS.CREATE_VIDEO_STREAM_EMPTY_FILES}
        paginationSettings={paginationSettings}
        loadMore={loadMore}
        loadedItemsCount={items?.length}
      />
    </ModalScreen>
  );
};

export default SelectVideoMediafilesModal;
